
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { defineComponent, computed, onMounted, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import moment from "moment";
import { tsXLXS } from "ts-xlsx-export";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const tableHeader = ref([
      {
        name: "No.",
        key: "rider_id",
        sortable: false,
      },
      {
        name: "Status",
        key: "status",
        sortable: false,
      },
      {
        name: "Rider name",
        key: "rider_name",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Start date",
        key: "rider_time_start",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Tel.",
        key: "rider_tel",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Food/Mes/Free",
        key: "total_paid_price",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Revenue",
        key: "revenue",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Com. 20%",
        key: "com",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Net revenue",
        key: "net_revenue",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Credit",
        key: "credit",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Tax com.",
        key: "tax_com",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "",
        key: "",
        sortingField: "status.label",
        sortable: false,
      },
    ]);
    const store = useStore();

    const reportList = computed(() => {
      return store.getters.riderReport;
    });

    const riderSummary = computed(() => {
      return store.getters.riderSummary;
    });

    const originalConfig = computed(() => {
      return store.state.ReportModule.originalRiderConfig;
    });

    onMounted(() => {
      setCurrentPageTitle("Rider Report");
    });

    function exportReportToExcel() {
      let data: Record<string, string>[] = [];
      for (let val of reportList.value) {
        const form = {
          STATUS: val.status,
          "RIDER NAME": val.rider_name,
          "START DATE": val.rider_time_start,
          "TEL.": val.rider_tel,
          "FOOD/MES/FREE": val["serviceFood/Mes/Free"],
          REVENUE: val.revenue,
          "COM. 20%": val.com,
          "NET REVENUE": val.net_revenue,
          CREDIT: val.credit,
          "TAX COM.": val.tax_com,
        };
        data.push(form);
      }

      const sumForm = {
        STATUS: "",
        "RIDER NAME": "TOTAL",
        "START DATE": "",
        "TEL.": "",
        "FOOD/MES/FREE": riderSummary.value["serviceFood/Mes/Free"],
        REVENUE: riderSummary.value.revenue,
        "COM. 20%": riderSummary.value.com,
        "NET REVENUE": riderSummary.value.net_revenue,
        CREDIT: riderSummary.value.credit,
        "TAX COM.": riderSummary.value.tax_com,
      };

      data.push(sumForm);

      tsXLXS().exportAsExcelFile(data).saveAsExcelFile(`Rider Report  ${moment(
        originalConfig.value.startDate
      ).format("YYYY/MM/DD")} -
                ${moment(originalConfig.value.endDate).format("YYYY/MM/DD")}`);
    }
    return {
      moment,
      originalConfig,
      tableHeader,
      reportList,
      riderSummary,
      exportReportToExcel,
    };
  },
});
